import Layout from '../layout/index.vue'

export default [
	{
		path: '/cost',
		name: 'cost',
		component: Layout,
		redirect: 'cost/put',
		children: [
			{
				path: 'put',
				component: () => import('../views/cost/put.vue')
			},
			{
				path: 'put_detail',
				component: () => import('../views/cost/put/detail.vue')
			},
			{
				path: 'grant',
				component: () => import('../views/cost/grant.vue')
			},
			{
				path: 'grant_new',
				component: () => import('../views/cost/grant_new.vue')
			},
			{
				path: 'contract',
				component: () => import('../views/cost/contract.vue')
			},
			{
				path: 'contract_detail',
				component: () => import('../views/cost/contract/detail.vue')
			},
			{
				path: 'supervision',
				component: () => import('../views/cost/supervision.vue'),
			},
			{
				path: 'supervision/edit',
				component: () => import('../views/cost/supervision/edit.vue')
			},
			{
				path: 'auth',
				component: () => import('../views/cost/auth.vue')
			},
			{
				path: 'attach',
				component: () => import('../views/cost/attach.vue')
			},
			{
				path: 'attach_detail',
				component: () => import('../views/cost/attach/detail.vue')
			},
			{
				path: 'unsettlement',
				component: () => import('../views/cost/unsettlement.vue')
			},
			{
				path: 'settlement',
				component: () => import('../views/cost/settlement.vue')
			},
			{
				path: 'settlement_edit',
				component: () => import('../views/cost/settlement/edit.vue')
			},
			{
				path: 'abnormal',
				component: () => import('../views/cost/abnormal.vue')
			},
			{
				path: 'template',
				component: () => import('../views/cost/template.vue')
			},
			{
				path: 'template_edit',
				component: () => import('../views/cost/template/edit.vue')
			},
			{
				path: 'simplicity_template',
				component: () => import('../views/cost/simplicity_template.vue')
			},
			
			{
				path: 'setup',
				component: () => import('../views/cost/setup.vue')
			},
			{
				path: 'put_edit',
				component: () => import('../views/cost/put/edit.vue')
			},
			{
				path: 'grant_edit',
				component: () => import('../views/cost/put/grant.vue')
			},
			{
				path: 'costStat',
				component: () => import('../views/cost/costStat.vue')
			},
			{
				path: 'closing_push_record',
				component: () => import('../views/cost/closing_push_record.vue')
			},
			{
				path: 'closing_push_details',
				component: () => import('../views/cost/closing_push_details.vue')
			},
			{
				path: 'cost_goods',
				component: () => import('../views/cost_goods/index.vue')
			},
		]
	},
];

