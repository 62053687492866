import req from "../methods/req"


export const layout_nav = function (data){
    return req({
        url:'/manage/api.index/index',
        data
    })
}

export const post_user = function (data){
    return req({
        url:'/manage/crm.common/getPostTreeUserList',
        data
    })
}

//当前接口只在动销退出时使用
export const rtLoginOut = (data) => req({url: '/manage/api.login/exitOut',...data})

