import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
		keep_live_route: [],// 缓存路由
		user_info:{},
		router_mode:'',
		table_list_seo_data:{},
		page_auth:{},
		app_auth:null,
		page_query:{}, // 页面参数
    },
    mutations: {
        set(state,payload){
            Object.keys(payload).forEach(e=>{
                if(Object.hasOwnProperty.call(state,e)){
                    state[e] = payload[e];
                }else{
                    console.error(`store未定义${e}属性`)
                }
            })
        },
		// 添加缓存路由
		add_keep_route(state, route) {
			let index = state.keep_live_route.indexOf(route)
			if (index == -1) {
				state.keep_live_route.push(route)
			}
		},
		// 删除缓存路由
		remove_keep_route(state, route) {
			let index = state.keep_live_route.indexOf(route)
			if (index > -1) {
				state.keep_live_route.splice(index, 1)
			}
		}
    },
    actions: {
    },
    modules: {
    },
})
