import Layout from '../layout/index.vue'

export default [
	{
		path: '/supervision',
		name: 'Supervision',
		component: Layout,
		redirect: 'supervision/index',
		children: [
			{
				path: 'index',//列表
				component: () => import('../views/supervision/index')
			},
			{
				path: 'edit',//编辑
				component: () => import('../views/supervision/edit')
			},
			{
				path: 'report',//报表
				component: () => import('../views/supervision/report')
			},
			{
				path: 'setting',//权限设置
				component: () => import('../views/supervision/setting')
			},
			
		]
	},
];