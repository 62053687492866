import req from "../methods/req"
import method from '../methods/method'

// 获取商品列表
export const getGoodsList = function (data) {
	return method.get_list({
		url: '/manage/crm.materiel/goodsList',
		...data,
	})
}

export const getDrpGoodsList = function (data) {
	return method.get_list({
		url: '/manage/crm.goods/getDrpGoodsList',
		...data,
	})
}

export const changeGoods = function (data) {
	return req({
		url: '/manage/crm.materiel/changeGoods',
		...data
	})
}

export const goodsDelete = function (data) {
	return req({
		url: '/manage/crm.materiel/goodsDelete',
		...data,
	})
}

// 获取广宣物料列表
export const getMaterielList = function (data) {
	return method.get_list({
		url: '/manage/crm.materiel/materielList',
		...data,
	})
}


export const changeMateriel = function (data) {
	return req({
		url: '/manage/crm.materiel/changeMateriel',
		...data
	})
}

export const competeList = function (data) {
	return method.get_list({
		url: '/manage/crm.materiel/competeList',
		...data,
	})
}

export const competeDelete = function (data) {
	return req({
		url: '/manage/crm.materiel/competeDelete',
		...data,
	})
}

export const changeCompete = function (data) {
	return req({
		url: '/manage/crm.materiel/changeCompete',
		...data,
	})
}
export const materielDelete = function (data) {
	return req({
		url: '/manage/crm.materiel/materielDelete',
		...data,
	})
}

export const freezerList = function (data) {
	return method.get_list({
		url: '/manage/crm.materiel/freezerList',
		...data,
	})
}

export const freezerLocus = function (data) {
	return req({
		url: '/manage/crm.materiel/freezerLocus',
		...data,
	})
}

export const getGoodsTitle = function (data) {
	return req({
		url: '/manage/crm.materiel/getGoodsTitle',
		...data,
	})
}

export const getGoodsTitleV2 = function (data) {
	return req({
		url: '/manage/crm.materiel/getGoodsV2',
		...data,
	})
}

export const terminalReport = function (data) {
	return req({
		url: '/manage/crm.materiel/terminalReport',
		...data,
	})
}


export const terminalReportSub = function (data) {
	return req({
		url: '/manage/crm.materiel/terminalReportSub',
		...data,
	})
}

export const terminalReportV2 = function (data) {
	return req({
		url: '/manage/crm.materiel/terminalReportV2',
		...data,
	})
}


export const terminalReportSubV2 = function (data) {
	return req({
		url: '/manage/crm.materiel/terminalReportSubV2',
		...data,
	})
}

export const spreadRatio = function (data) {
	return req({
		url: '/manage/crm.materiel/spreadRatio',
		...data,
	})
}

export const competeReport = function (data) {
	return req({
		url: '/manage/crm.materiel/competeReport',
		...data,
	})
}


export const competeReportSub = function (data) {
	return req({
		url: '/manage/crm.materiel/competeReportSub',
		...data,
	})
}


export const changeFreezer = function (data) {
	return req({
		url: '/manage/crm.materiel/changeFreezer',
		...data,
	})
}

export const freezerCensus = function (data) {
	return req({
		url: '/manage/crm.freezer/freezerCensus',
		...data,
	})
}


export const setReportMenuList2 = function (data) {
	return req({
		url: '/manage/crm.report/setReportMenuList',
		...data,
	})
}



export const getSub = function (data) {
	return req({
		url: '/manage/crm.freezer/getSub',
		...data,
	})
}

export const getFreezer = function (data) {
	return method.get_list({
		url: '/manage/crm.freezer/getFreezer',
		...data,
	})
}

export const census = function (data) {
	return req({
		url: '/manage/crm.freezer/census',
		...data,
	})
}

//广宣 物料  头部
export const costTopReport = function (data) {
	return req({
		url: '/manage/crm.report/costTopReport',
		...data,
	})
}
//costReport
export const costReport = function (data) {
	return req({
		url: '/manage/crm.report/costReport',
		...data,
	})
}

export const getCostSub = function (data) {
	return req({
		url: '/manage/crm.report/getSub',
		...data,
	})
}


export const getCostInfo = function (data) {
	return method.get_list({
		url: '/manage/crm.report/getCostInfo',
		...data,
	})
}


//投放报表 manage/crm.investment/launchReport

export const launchReport = function (data) {
	return req({
		url: '/manage/crm.investment/launchReport',
		...data,
	})
}

//manage/crm.investment/getLaunchSub

export const getLaunchSub = function (data) {
	return req({
		url: '/manage/crm.investment/getLaunchSub',
		...data,
	})
}
export const getLaunchInfo = function (data) {
	return method.get_list({
		url: '/manage/crm.investment/getLaunchInfo',
		...data,
	})
}


//发放 manage/crm.investment/investmentReport

export const investmentReport = function (data) {
	return req({
		url: '/manage/crm.investment/investmentReport',
		...data,
	})
}


export const getInvestmentSub = function (data) {
	return req({
		url: '/manage/crm.investment/getInvestmentSub',
		...data,
	})
}
export const getInvestmentInfo = function (data) {
	return method.get_list({
		url: '/manage/crm.investment/getInvestmentInfo',
		...data,
	})
}

//manage/crm.report/setReportMenu


export const setReportMenu = function (data) {
	return req({
		url: '/manage/crm.report/setReportMenu',
		data:data,
	})
}

//manage/crm.report/setReportSort


export const setReportSort = function (data) {
	return req({
		url: '/manage/crm.report/setReportSort',
		data:data,
	})
}

// 冰柜报废
export const scrapBinding = function (data) {
	return req({
		url: '/manage/crm.materiel/scrapBinding',
		...data
	})
}

// 冰柜转绑
export const bindingTransfer = function (data) {
	return req({
		url: '/manage/crm.materiel/bindingTransfer',
		...data
	})
}

//冰柜遗失
export const bindingLost = function (data) {
	return req({
		url: '/manage/crm.materiel/bindingLost',
		...data,
	})
}

// 冰柜启用禁用
export const changeStatus = function (data) {
	return req({
		url: '/manage/crm.materiel/changeStatus',
		...data
	})
}

// 分类列表（分类管理）
export const goodsCate = function (data) {
	return req({
		url: '/manage/crm.goods/goodsCate',
		...data
	})
}

// 分类表头
export const headGoodsCate = function (data) {
	return req({
		url: '/manage/crm.materiel/goodsCate',
		...data
	})
}

// 添加分类（分类管理）
export const addCate = function (data) {
	return req({
		url: '/manage/crm.goods/addCate',
		...data
	})
}

// 编辑分类（分类管理）
export const editCate = function (data) {
	return req({
		url: '/manage/crm.goods/editCate',
		...data
	})
}

// 删除分类（分类管理）
export const delCate = function (data) {
	return req({
		url: '/manage/crm.goods/delCate',
		...data
	})
}

// 恢复分类（分类管理）
export const recoveryCate = function (data) {
	return req({
		url: '/manage/crm.goods/recoveryCate',
		...data
	})
}

// 添加商品/编辑商品（商品列表/公司商品）
export const modifyGoods = function (data) {
	return req({
		url: '/manage/crm.goods/modifyGoods',
		...data
	})
}

// 获取商品预警设置（商品列表/公司商品）
export const getSetting = function (data) {
	return req({
		url: '/manage/crm.goods/getSetting',
		...data
	})
}

// 保存商品预警设置（商品列表/公司商品）
export const saveSetting = function (data) {
	return req({
		url: '/manage/crm.goods/saveSetting',
		...data
	})
}

// 获取品牌列表
export const brandSelect = function (data) {
	return req({
		url: '/manage/crm.brand/getBrandSelect',
		...data
	})
}

// 获取品牌列表
export const getBrandList = function (data) {
	return method.get_list({
		url: '/manage/crm.brand/getBrandList',
		...data,
	})
}

// 添加/编辑品牌
export const saveBrand = function (data) {
	return req({
		url: '/manage/crm.brand/saveBrand',
		...data
	})
}

// 品牌详情
export const getBrandDetail = function (data) {
	return req({
		url: '/manage/crm.brand/getBrandDetail',
		...data
	})
}

// 删除品牌
export const delBrand = function (data) {
	return req({
		url: '/manage/crm.brand/delBrand',
		...data
	})
}
// 删除冰柜
export const delGoodsBinding = function (data) {
	return req({
		url: '/manage/crm.materiel/delGoodsBinding',
		...data
	})
}



// 获取资产编号
export const getBrandSelect = function (data) {
	return req({
		url: '/manage/crm.materiel/getBrandSelect',
		...data
	})
}

// 获取冰柜详情
export const getBindingInfo = function (data) {
	return req({
		url: '/manage/crm.materiel/getBindingInfo',
		...data
	})
}

// 保存冰柜信息
export const saveBindingInfo = function (data) {
	return req({
		url: '/manage/crm.materiel/saveBindingInfo',
		...data
	})
}

// 模版
export const exportBindingTemplate = function (data) {
	return req({
		url: '/manage/crm.materiel/exportBindingTemplate',
		...data
	})
}

// 产品型号
export const getFreezerModel = function (data) {
	return req({
		url: '/manage/crm.materiel/getFreezerModel',
		...data
	})
}
// 同步商品

export const syncGoodsInfo = function (data) {
	return req({
		url: '/manage/crm.goods/syncGoodsInfo',
		...data
	})
}

// 订单商品同步
export const syncDrpGoodsInfo = function (data) {
	return req({
		url: '/manage/crm.goods/syncDrpGoodsInfo',
		...data
	})
}
// 订单商品详情
export const getDrpGoodsDetail = function (data) {
	return req({
		url: '/manage/crm.goods/getDrpGoodsDetail',
		...data
	})
}

// 修改工厂
export const changeFactoryGoods = function (data) {
	return req({
		url: '/manage/crm.goods/changeFactoryGoods',
		...data
	})
}

// 商品单位列表
export const getDrpGoodsUnit = function (data) {
    return method.get_list({
		url: '/manage/crm.goods/getDrpGoodsUnit',
		...data,
	})
}

// 获取商品分类单位
export const getDrpGoodsUnitCate = function (data) {
	return req({
		url: '/manage/crm.goods/getDrpGoodsUnitCate',
		...data
	})
}

// 保存商品单位
export const addDrpGoodsUnit = function (data) {
	return req({
		url: '/manage/crm.goods/addDrpGoodsUnit',
		...data
	})
}

// 删除单位
export const delDrpGoodsUnit = function (data) {
	return req({
		url: '/manage/crm.goods/delDrpGoodsUnit',
		...data
	})
}

// 修改单位状态
export const changeDrpGoodsUnitStatus = function (data) {
	return req({
		url: '/manage/crm.goods/changeDrpGoodsUnitStatus',
		...data
	})
}