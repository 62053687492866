import store from '@/store'

export default {
	auth: {
		bind:function(el,binding){
			let auth = store.state.page_auth[binding.value]
			
			if(!auth && auth !== undefined){
				el.style.display = 'none'
			}
		},
		update:function(el,binding){
			let auth = store.state.page_auth[binding.value];
			if(!auth && auth !== undefined){
				el.style.display = 'none'
			}else if(auth){
				// el.style.display = 'block';
			}
		}
	}
}