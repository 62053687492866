import Layout from '../layout/index.vue'

export default [
	{
		path: '/',
		name: 'clock',
		component: Layout,
		redirect: '/clock',
		children: [
			{
				path: '/organization/clock',
				component: () => import('../views/organization/clock.vue'),
				meta: {
					title: '工作地点'
				},
			},
			{
				path: '/organization/clockCat',
				component: () => import('../views/organization/clockCat.vue'),
				meta: {
					title: '工作区域'
				},
			},
			{
				path: '/organization/sortList',
				component: () => import('../views/organization/compent/sortClockList.vue'),
				meta: {
					title: '排序'
				},
			},
			{
				path: '/organization/employeeLabel',
				component: () => import('../views/organization/employeeLabel.vue'),
				meta: {
					title: '员工标签'
				},
			},
			
			{
				path: '/organization/grade',
				component: () => import('../views/organization/grade.vue'),
				meta: {
					title: '职级管理'
				},
				
			},
			{
				path: '/organization/rank',
				component: () => import('../views/organization/rank.vue'),
				meta: {
					title: '职务管理'
				},
				
			},
			{
				path: '/organization/rank',
				component: () => import('../views/organization/rank.vue'),
				meta: {
					title: '职务详情'
				},
				
			},
			{
				path: '/organization/sortRank',
				component: () => import('../views/organization/compent/sortRank.vue'),
				meta: {
					title: '职务排序'
				},
				
			},
			
		
		]
	},
];