import {getAllButton} from '@/api/admin.js'


export default function () {
	return new Promise((resolve,reject)=>{
		// getAllButton().then(res=>{
		// 	resolve(res.data)
		// }).catch(res=>{
		// 	reject(res);
		// })
	})
}