<template>
    <a v-if="type == 'export'" :href="url | export_form" target="_blank">
        <a-button class="a_btn">{{name}}</a-button>
    </a>
    <a-button @click="export_click" v-else class="a_btn">{{name}}</a-button>
</template>
<script>
export default {
    props: {
		name:{
			type:String,
			default:'导出'
		},
        type: {
            type: String,
            default: 'export', // export 导出  pre 预导出
        },
        url: {
            type: String,
            default: ''
        }
    },
    methods: {
        export_click() {
            this.$req({
                base_url:this.url,
                info: true,
            });
        }
    }
}
</script>