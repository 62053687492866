import Layout from '../layout/index.vue'

export default [
	{
		path: '/traceability',
		name: 'Traceability',
		component: Layout,
		redirect: 'traceability/index',
		children: [
			{
				path: 'index',//溯源码管理
				component: () => import('../views/traceability/index')
			},
			{
				path: 'report',//报表管理
				component: () => import('../views/traceability/report.vue')
			},
			{
				path: 'code',//溯源码详情列表
				component: () => import('../views/traceability/code/index.vue')
			},
			{
				path: 'reportLine',//产线报表
				component: () => import('../views/traceability/reportLine.vue')
			},
			{
				path: 'template',//h5展示界面
				component: () => import('../views/traceability/template.vue')
			},
			{
				path: 'outboundOrder',//出库单查询
				component: () => import('../views/traceability/outboundOrder/index.vue')
			},
			{
				path: 'device',//设备管理
				component: () => import('../views/traceability/device/index.vue')
			},
			{
				path: 'line',//产线管理
				component: () => import('../views/traceability/line/index.vue')
			},
			{
				path: 'goods_setting',//产线管理
				component: () => import('../views/traceability/device/goods_setting.vue')
			},
		]
	},
];