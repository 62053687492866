import Layout from '../layout/index.vue'

export default [
	{
		path: '/qmsQuota',
		name: 'QmsQuota',
		component: Layout,
		redirect: 'qmsQuota/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/qms/quota/index.vue')
			},
            {
				path: 'addSave',
				component: () => import('../views/qms/quota/addSave.vue')
			},
            {
				path: 'classify',
				component: () => import('../views/qms/quota/classify.vue')
			}
		]
	},
    {
		path: '/qmsProduct',
		name: 'QmsProduct',
		component: Layout,
		redirect: 'qmsProduct/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/qms/product/index.vue')
			}
		]
	},
    {
		path: '/qmsSupplier',
		name: 'QmsSupplier',
		component: Layout,
		redirect: 'qmsSupplier/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/qms/supplier/index.vue')
			},
            {
				path: 'details',
				component: () => import('../views/qms/supplier/details.vue')
			}
		]
	},
    {
		path: '/qmsfactory',
		name: 'Qmsfactory',
		component: Layout,
		redirect: 'qmsFactory/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/qms/factory/index.vue')
			}
		]
	},
    {
		path: '/qmsTestType',
		name: 'QmsTestType',
		component: Layout,
		redirect: 'qmsTestType/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/qms/testtype/index.vue')
			}
		]
	},
];