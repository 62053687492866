import store from '../store/index.js'
export default {
	code: 0,
	name: null,
	value: null,
	type: null,
	add(name) {
		this.name = name;
		store.commit('add_keep_route', name)
	},
	remove(name){
		this.name = name;
		store.commit('remove_keep_route', name)
	},
	get(fun) {
		if (this.code == 1) {
			this.code = 0;
			this.name && store.commit('remove_keep_route', this.name)
			fun({
				value: this.value,
				type: this.type,
			})
			this.value = null
			this.type = null
		}
	},
	set(data) {
		this.code = 1;
		if (data.value !== null) this.value = data.value;
		if (data.type !== null) this.type = data.type;
	}
}