
// 是否为测试版（true 为是，false 为假）
let debug = true

// 正式版本
let host = 'http://crm-manage.runtian.cn'	//接口地址
// let host = 'https://seller-api.runtian.cn'	//接口地址-电子签收指定接口地址
let stagingUrl = 'http://hr.runtian.cn/public/deskPage/index'	//工作台页面地址 

if(debug){
	// 测试版本
	// host = 'http://crm-dev.waakuu.com'
	// dx-test-api.52shanglu.com
	host = 'https://pmcrm.52shanglu.com'	//接口地址
	stagingUrl = 'https://personnel-manage.52shanglu.com/public/deskPage/index'
}
export default {
	host,	//域名
	debug,	//环境状态
	stagingUrl,	//工作台页面地址
	version: 'v1.0.0',	//当前版本
	// 默认分页配置
	pagination: {
		defaultPageSize: 15,
		showTotal: (total, range) => `共${total}条`,
	},
	color: {
		primary: '#1890ff',
		error: '#f5222d',
		warning: '#faad14'
	},
	// 列表搜索条件 默认设置
	form_data_seo: {
		show_submit_btn: true,
		submit_name: "查询",
		layout: "inline"
	},
	// 全部筛选条件
	status_all: [
		{
			key: '',
			value: "全部"
		},
	],
	// 默认状态
	status_list: [
		{
			key: 1,
			value: "启用"
		},
		{
			key: 0,
			value: "禁用"
		},
	],
	// 审批状态
	approval_status_list: [
		{
			key: 0,
			value: '待审批'
		},
		{
			key: 1,
			value: '审批中'
		},
		{
			key: 2,
			value: '已同意'
		},
		{
			key: 4,
			value: '待发放'
		},
		{
			key: -1,
			value: '已拒绝'
		},
		{
			key: -2,
			value: '已撤回'
		},
	],

	user_status_list: [
		{
			key: '',
			value: "全部"
		},
		{
			key: "-1",
			value: "离职"
		},
		{
			key: "1",
			value: "在职"
		}
	]
}