import Layout from '../layout/index.vue'

export default [
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/user/login.vue')
	},
	{
		path: '/user',
		name: 'User',
		component: Layout,
		redirect: 'user/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/user/index.vue')
			},
			{
				path: 'index_edit',
				meta: {
					select_path: 'user/index'
				},
				component: () => import('../views/user/index/edit.vue')
			}
		]
	},
	{
		path: '/personnel',
		name: 'Personnel',
		component: Layout,
		redirect: '/personnel/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/personnel/index.vue')
			}
		]
	},
	{
		path: '/job',
		name: 'Job',
		component: Layout,
		redirect: 'job/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/job/index.vue')
			}
		]
	},
];