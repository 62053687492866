import Layout from '../layout/index.vue'

export default [
	{
		path: '/driver',
		name: 'Driver',
		component: Layout,
		redirect: 'driver/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/driver/index.vue')
			},
			{
				path: 'details',
				component: () => import('../views/electronic/driver/details.vue')
			},
		]
	},
	{
		path: '/ware_house',
		name: 'Ware_house',
		component: Layout,
		redirect: 'ware_house/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/ware_house/index.vue')
			},
			{
				path: 'details',
				component: () => import('../views/electronic/ware_house/details.vue')
			},
			{
				path: 'product',
				component: () => import('../views/electronic/ware_house/product.vue')
			},
			
		]
	},
	{
		path: '/send',
		name: 'send',
		component: Layout,
		redirect: 'send/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/send/index.vue')
			},
			{
				path: 'details',
				component: () => import('../views/electronic/send/details.vue')
			},
			// {
			// 	path: 'product',
			// 	component: () => import('../views/electronic/send/product.vue')
			// },
			
		]
	},
	{
		path: '/barrel',
		name: 'barrel',
		component: Layout,
		redirect: 'barrel/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/barrel/index.vue')
			},
			{
				path: 'details',
				component: () => import('../views/electronic/barrel/details.vue')
			},
			// {
			// 	path: 'product',
			// 	component: () => import('../views/electronic/send/product.vue')
			// },
			
		]
	},
	{
		path: '/template',
		name: 'template',
		component: Layout,
		redirect: 'template/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/template/index.vue')
			},
			// {
			// 	path: 'details',
			// 	component: () => import('../views/electronic/template/details.vue')
			// },
			// {
			// 	path: 'product',
			// 	component: () => import('../views/electronic/send/product.vue')
			// },
			
		]
	},
	{
		path: '/customer_seller',
		name: 'customer_seller',
		component: Layout,
		redirect: 'customer_seller/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/customer/index.vue')
			},
			
		]
	},
	{
		path: '/empty',
		name: 'empty',
		component: Layout,
		redirect: 'empty/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/empty/index.vue')
			},
			
		]
	},
	{
		path: '/send_order',
		name: 'send_order',
		component: Layout,
		redirect: 'send_order/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/order/index.vue')
			},
			{
				path: 'details',
				component: () => import('../views/electronic/order/details.vue')
			}
			
		]
	},
	{
		path: '/deposit',
		name: 'deposit',
		component: Layout,
		redirect: 'deposit/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/deposit/index.vue')
			},
			{
				path: 'detail',
				component: () => import('../views/electronic/deposit/detail.vue')
			},
		]
	},
	{
		path: '/tray',
		name: 'tray',
		component: Layout,
		redirect: 'tray/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/tray/index.vue')
			},
			{
				path: 'detail',
				component: () => import('../views/electronic/tray/detail.vue')
			},
			
		]
	},
	{
		path: '/tray_order',
		name: 'tray_order',
		component: Layout,
		redirect: 'tray_order/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/tray_order/index.vue')
			},
			{
				path: 'detail',
				component: () => import('../views/electronic/tray_order/detail.vue')
			},
		]
	},
	{
		path: '/seller',
		name: 'seller',
		component: Layout,
		redirect: 'seller/index',
		children: [
			{
				path: 'index',
				component: () => import('../views/electronic/seller/index.vue')
			},
			{
				path: 'detail',
				component: () => import('../views/electronic/seller/detail.vue')
			},
		]
	},
	
];